import React from 'react';
import { Helmet } from 'react-helmet';

import SiteHeader from '../../../components/SiteHeader/type4';
import SectionHeader from '../../../components/SectionHeader';
import KinetikFeature from '../../../components/KinetikFeature';
import ConsultantFeature from '../../../components/ConsultantFeature';
import Footer from '../../../components/Footer';
import ProjectFeature from '../../../components/ProjectFeature';
import CTA from '../../../components/CTA';
import SiteNavigation from '../../../components/SiteHeader/navigation';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// @ts-ignore
import MidtkraftLogo from '../../../../static/Logos/midtkraft.svg';

// @ts-ignore
import KinetikNettGif from '../../../../static/KinetikNett/kinetiknett.gif';

// @ts-ignore
import KinetikNettMobileGif from '../../../../static/KinetikNett/mobile.gif';

// @ts-ignore
import KinetikNettAlertGif from '../../../../static/KinetikNett/alert.gif';

// @ts-ignore
import HeaderImage from '../../../../static/Elhubsok/elhubSearch.svg';

// @ts-ignore
import FeatureImage1 from '../../../../static/Elhubsok/Search.svg';

// @ts-ignore
import FeatureImage2 from '../../../../static/Elhubsok/Result.svg';

import LogoCloud from '../../../components/LogoCloud';

import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon, CheckIcon } from '@heroicons/react/outline'

const tiers = [
    {
        name: 'Elhub Søk',
        href: '/om/#kontakt',
        priceMonthly: 1100,
        description: 'Dette gir tilgang til løsning med søk etter målere og uthenting av basisinformasjon på måler. ',
        features: [
            'Med eller uten målernummer',
            'Brukervennlig og enkelt',
            'Mobiltilpasset',
        ],
    }
]

const overviewFeatures = [
    {
        id: 1,
        name: 'Med eller uten målernummer',
        description:
            'Finn målepunkt med bruk av målernummer eller personnummer. Wilcard søk støttes også.',
        icon: GlobeAltIcon,
    },
    {
        id: 2,
        name: 'Brukervennlig og enkelt',
        description:
            'Ingen unødvendig informasjon som må fylles ut. Historikk for å se siste søk.',
        icon: ScaleIcon,
    },
    {
        id: 3,
        name: 'Mobiltilpasset',
        description:
            'Bruk tjenesten fra alle mulige enheter.',
        icon: LightningBoltIcon,
    },
]
const alertFeatures = [
    {
        id: 1,
        name: 'Bulk operasjoner',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: AnnotationIcon,
    },
    {
        id: 2,
        name: 'Noe annet',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: MailIcon,
    },
    {
        id: 3,
        name: 'Noe annet',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: MailIcon,
    },
]

function ELHUB({ props }) {
    return (
        <div className="bg-gray-50">
            <Helmet>
                <title>Convivial - ELhub Søk</title>
                <link rel="canonical" href="https://convivial.no/tjenester/elhubsok" />
                <meta name="description" content="Oppslag for strømsalg og meglere med Elhub Søk" />
                <script async defer data-domain="convivial.no" src="https://plausible.io/js/plausible.js"></script>
            </Helmet>

            <main>
                <SiteNavigation bgColor="bg-gray-50" />
                <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 mb-16">
                    <div className="sm:text-center">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl font-header">
                            <span className="block"> Oppslag for&nbsp;strømsalg</span>{' '}
                            <span className="block text-aktiverhan">ELHub Søk</span>
                        </h1>
                        <p className="mt-3 max-w-md sm:mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                            Finn riktig informasjon kjapt og enkelt med Elhub søk
                        </p>
                    </div>
                </div>

                <div className="bg-gray-50">
                    <div className="relative overflow-hidden">
                        <div className="relative">
                            <div className="absolute inset-0 flex flex-col" aria-hidden="true">
                                <div className="flex-1" />
                                <div className="flex-1 w-full bg-white" />
                            </div>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 pb-6">

                                <img
                                    className="relative rounded-lg w-full"
                                    src={HeaderImage}
                                    alt="Kinetik nett interface"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
                    <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                        <div className="relative">
                            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                Skreddersydd for&nbsp;strømsalg
                            </h2>
                            <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                                Med EL Hub søk kan det tilbys en tjeneste til strømsalg/meglere for selvbetjent uthenting av forbruk og informasjon om anlegg.
                            </p>
                        </div>

                        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                            <div className="relative">
                                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                                    Smidig og brukervennelig
                                </h3>
                                <p className="mt-3 text-lg text-gray-500">
                                    Tilpasset mobile flater og enkel i bruk. Tjenesten kan benyttes på telefoner/tablets eller desktops.
                                </p>

                                <dl className="mt-10 space-y-10">
                                    {overviewFeatures.map((item) => (
                                        <div key={item.id} className="relative">
                                            <dt>
                                                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-kinetik text-white">
                                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                                </div>
                                                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                                            </dt>
                                            <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>

                            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                                <img
                                    className="relative mx-auto"
                                    width={490}
                                    src={FeatureImage1}
                                    alt=""
                                />
                            </div>
                        </div>


                    </div>
                </div>



                <div className="bg-kinetik-dark">
                    <div className="pt-12 sm:pt-16 lg:pt-24">
                        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                            <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
                                <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">PRISER</h2>
                                <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
                                    Ingen skjulte&nbsp;priser
                                </p>
                                <p className="text-xl text-gray-300">
                                    Skjulte priser eller pris på forespørsel er utrolig irriterende. Vi ønsker å være så transparente som mulig, derfor synliggjør vi de faste kostnadene for tjenesten.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 bg-gray-50 sm:mt-12 lg:mt-16">
                        <div className="relative">
                            <div className="absolute inset-0 h-3/4 bg-kinetik-dark" />
                            <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="max-w-md mx-auto space-y-4 lg:max-w-1xl lg:grid lg:grid-cols-1 lg:gap-5 lg:space-y-0 ">
                                    {tiers.map((tier) => (
                                        <div key={tier.name} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                                                <div>
                                                    <h3
                                                        className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-kinetik text-kinetik-dark"
                                                        id="tier-standard"
                                                    >
                                                        {tier.name}
                                                    </h3>
                                                </div>
                                                <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                                                    {tier.priceMonthly}
                                                    <span className="ml-1 text-2xl font-medium text-gray-500">kr/mnd</span>
                                                </div>
                                                <p className="mt-5 text-lg text-gray-500">{tier.description}</p>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                                                <ul className="space-y-4">
                                                    {tier.features.map((feature) => (
                                                        <li key={feature} className="flex items-start">
                                                            <div className="flex-shrink-0">
                                                                <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
                                                            </div>
                                                            <p className="ml-3 text-base text-gray-700">{feature}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className="rounded-md shadow">
                                                    <a
                                                        href={tier.href}
                                                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                                                        aria-describedby="tier-standard"
                                                    >
                                                        Ta kontakt
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
                            <div className="max-w-md mx-auto lg:max-w-1xl">
                                <div className="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
                                    <div className="flex-1">
                                        <div>
                                            <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800">
                                                Etablering av tjenesten
                                            </h3>
                                        </div>
                                        <div className="mt-4 text-lg text-gray-600">
                                            Etableringen inneholder konfigurasjon av tjenesten mot ELhub og opprettelse av brukere. <span className="font-semibold text-gray-900">5000 kr/engangsbeløp</span>.
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CTA
                    demo={true}
                    header="Fortsatt nysgjerrig?"
                    subHeader="Book en demo eller kontakt&nbsp;oss."
                    bgColor="bg-gray-50"
                    contactButtonColor="bg-kinetik"
                    demoButtonColor="text-kinetik"
                />

            </main>
            <Footer />
        </div>
    );
}

export default ELHUB;
